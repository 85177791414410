import { KEY_CLIENT_ID } from "~/configs/consts";

export const getClientIdFromStorage = (): string | null => {
  try {
    const item = window.localStorage.getItem(KEY_CLIENT_ID);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};
